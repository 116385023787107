<template>
  <div
    class="flex flex-col items-center justify-center space-y-2 px-4 py-8"
  >
    <p class="text-lg">
      'Special Page' Content Block
    </p>
    <p class="text-sm text-gray-700">
      When used on a dynamic page, this will render the page's content here.
    </p>
  </div>
</template>
<script>
export default {
  friendlyName: 'Special Page Content Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
